.button {
	background: $primary;
   color: $white;
	border-radius: .15rem;
	padding: .95rem 1.5rem;
	font-family: $headline;
   font-size: 1.3rem;
   font-weight: 700;
	display: inline-block !important;
	position: relative;
	overflow: hidden;
	transition: $bounce;
	margin: 0;
	outline: 0;
	&.small {
		padding: .55rem .75rem;
	}	
	@include tablet {
		padding: .754rem 1rem;
		margin: 0;
		&.small {
			padding: .75rem 1rem;
		}
	}		
	@include md {
		padding: 0.75rem 1.25rem;
		margin: 0;	
		&.small {
			padding: .85rem 1.25rem;
		}		
	}		
	&:before,
	&:after {
		@include size(100%,50%);
		display: block;
		content: "";
		z-index: 10;
		transition: $bounce;
		opacity: .4;
	}
	&:before {
		@include absolute(top 0 left 0);
	}
	&:after {
		@include absolute(bottom 0 left 0);
	}
	&:hover {
		transform: scale(1.1);
		text-decoration: none !important;
		&:before,
		&:after{
			opacity: .8;
		}	
	}
	&:active {
		transform: scale(.95);		
		&:before,
		&:after{
			opacity: .3;
		}	
	}
	&:focus {
  	box-shadow: $button-shadow-hover, 0 0 0 2px $green;
	}
	&.blue {
		background: $secondary;
      color: $white;
	}
	&.white {
		background: $white;	
		.button-text {
		  color: $primary;
		}
	}
   &.large {
      font-size: 150%;
   }
}

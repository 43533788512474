.main-header {
   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#004d9f+0,004d9f+100&1+0,0+100 */
   background: -moz-linear-gradient(top,  rgba(0,77,159,1) 0%, rgba(0,77,159,0) 100%); /* FF3.6-15 */
   background: -webkit-linear-gradient(top,  rgba(0,77,159,1) 0%,rgba(0,77,159,0) 100%); /* Chrome10-25,Safari5.1-6 */
   background: linear-gradient(to bottom,  rgba(0,77,159,1) 0%,rgba(0,77,159,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#004d9f', endColorstr='#00004d9f',GradientType=0 ); /* IE6-9 */
	width: 100%;
	position: fixed;
   height: 90px;
	left: 0;
	top: 0;
	z-index: 1000;
	transition: $slow;
   @include desktop {
      height: 200px;
   }
}
.fixed .main-header, .interior .main-header {
   background: -moz-linear-gradient(top,  rgba(0,77,159,1) 0%, rgba(0,77,159,1) 100%); /* FF3.6-15 */
   background: -webkit-linear-gradient(top,  rgba(0,77,159,1) 0%,rgba(0,77,159,1) 100%); /* Chrome10-25,Safari5.1-6 */
   background: linear-gradient(to bottom,  rgba(0,77,159,1) 0%,rgba(0,77,159,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
   height: 90px;
   transition: $slow;
}

.primary-nav {
   z-index: 1000;
   width: auto;
   opacity: 1.0;
   position: absolute;
   left: 4rem;
	display: none;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-content: stretch;
	align-items: flex-end;		
	transition: $slow;
   @include tablet {
   }
   @include tablet-wide {
   }
	@include desktop {
		padding-top: 2rem;
      display: flex;
	}
	&-grouping {
		&.left,
		&.right {
			display: none;
			width: 100%;
			@include desktop {
				display: block;
				flex: 1 1 auto;
				align-self: auto;
			}
		}			
		&.left {
			text-align: right;
		}
		&.right {
			text-align: left;
		}	
		&.logo-container {
			flex: 0 1 8rem;
			transition: $slow;
			@include tablet {
				flex: 0 1 11rem;
			}			
			@include desktop {
				flex: 0 1 20rem;
				align-self: baseline;
			}
			@include md {
				flex: 0 1 27rem;
				align-self: baseline;
			}			
		}
	}
	&-item {
		font-size: 1.0em;
		display: inline-block;
		color: $white;
		text-decoration: none;
		cursor: pointer;	
		text-transform: uppercase;
		position: relative;
		@include tablet {
		font-size: .75em;
		}			
		@include desktop {
			font-size: 0.9em;
		}	
		@include md {
			font-size: 1.0em;
		}				
		&:hover {
			.primary-nav {
				transition: $slow;
			  &-link { 
  				background: $white;
            color: $primary;
			  }			  
			}				
			.primary-subnav {
				opacity: 1;
				visibility: visible;
				transition: none;
			}
		}
		&:focus {
			.primary-nav {
			  &-link { 
  				color: $primary;
      		box-shadow: inset 0 0 0 2px $primary;
			  }			  
			}				
			.primary-subnav {
				opacity: 1;
				visibility: visible;
				transition: none;
			}
		}
		&.active {
  		&:before {
  			@include size(0, 0);
  			@include absolute(left 50% bottom 0%);			
  			z-index: 200;
  			border: solid transparent;
  			content: " ";
  			pointer-events: none;
  			border-color:  rgba($secondary, 0);
  			border-bottom-color: $secondary; 
  			border-width: .5rem;
  			margin-left: -.5rem;
  		}
  		&:hover {
  			border-color: darken($primary, 15%);
  			border-bottom-color: darken($primary, 15%); 
			}
		}
	}	
	&-list {
		display: inline-block;
		margin: 0 auto;
		padding: 0;
		font-family: $nav;
      font-weight: 500;
		list-style: none;
		position: relative;
	}
	&-link {
		color: $white;
		transition: $basic;	
		padding: .5rem 0.5rem;
		display: block;
		@include md {
  		padding: .5rem 0.75rem;
		}
		&:focus {
  		background: $teal;
      border-radius: .2rem;
  		color: $blue;
		}
	}
}
.primary-subnav {
   background: $darkblue;
	box-shadow: $shadow;
	width: 100%;
	min-width: 14rem;
	list-style: none;
	margin: 0;
	padding: .75rem;
	opacity: 0;
	visibility: hidden;
	@include absolute(top 100% left 50%);
	transform: translateX(-50%);
	transition: none;
	&:before {
		@include size(0, 0);
		@include absolute(left 50% bottom 100%);			
		z-index: 200;
		border: solid transparent;
		content: " ";
		pointer-events: none;
		border-color: rgba(darken($primary, 10%), 0);
		border-bottom-color: $blue; 
		border-width: .5rem;
		margin-left: -.5rem;				
	}		
	&-li {
		padding: 0;
		margin: 0;
		display: block;
      border-bottom: 1px dotted $lightblue;
      &:last-child {
         border-bottom: 0;
      }
	}
	&-link {
		display: block;
		padding: .5rem .75rem;
		color: $white;
		text-transform: none;
		font-family: $nav;
		font-size: 1.0rem;
		font-weight: 500;
		text-align: left;
		transition: $slow;
		&:hover {
         color: $white;
			background: darken($darkblue, 15%) !important;
		}
		&:focus {
         color: $white;
			background: darken($darkblue, 50%) !important;
		}		
	}
}
.fixed .primary-nav, .interior .primary-nav {
   visibility: hidden;
   opacity: 0;
   transition: $slow;
}

.primary-extras {
   width: auto;
   position: absolute; 
   top: 0;
   right: 1rem;
	padding-top: 1.25rem;
   display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-content: stretch;
	align-items: flex-end;		
	transition: $slow;
   @include tablet {
      padding-top: 1.5rem;
   	right: 2rem;
	}	
	@include desktop {
      padding-top: 1.5rem;
	}
   @include md {
   	right: 3rem;
	}	
   @include lg {
   	right: 5rem;
	}	
   &-share {
      font-size: 1.1rem;
      margin-right: 1rem;
      top: 0;
      position: relative;
      display: none;
      @include tablet {
         display: inline-block;
   	}	
   	@include desktop {
         display: inline-block;
   	}
   }
   &-links {
      transform: scale(0.75);
      @include tablet {
         transform: none;
      }
      &-button {
         padding: .5rem 1rem;
         text-transform: uppercase;
         font-size: 1.1rem !important;
         font-weight: 700;
         background: $white;
         color: $blue !important;
         &:focus {
            background: $blue;
            color: $white !important;
         }
         &:hover {
            color: $blue !important;
         }
      }
   }
}
.primary-extras .social-link {
   margin-right: 0.25rem;
   display: inline-block;
   top: -.5rem;
   border-radius: .2rem;
   padding: .3rem;
   width: 28px;
   &:hover {
   background: white;
   }
}
.primary-extras .social-link svg {
   width: 100%;
   fill: $white;
}
.primary-extras .social-link:hover svg {
   fill: $blue;
}
.bar {
width: 100%;
   height: .5rem;
   background: #febdfd;
   background: -moz-linear-gradient(left, #febdfd 10%, #6ca5e4 90%);
   background: -webkit-linear-gradient(left, #febdfd 10%, #6ca5e4 90%);
   background: linear-gradient(to right, #febdfd 10%, #6ca5e4 90%);
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#febdfd', endColorstr='#6ca5e4',GradientType=1 );
   @include desktop {
   height: 1.0rem;
   }
}

@include md {
  font-size: 1.2rem;
}

%footer-link {
	padding: .2rem;
	color: $blue;
	transition: $basic;
	margin: 0 auto;
	&:hover {
		color: $primary;
	}
	&:focus {
	  color: darken($primary, 10%);
  	box-shadow: 0 0 0 1px $green;
	}	  	
	@include tablet {
		font-size: .8rem;
	}
	@include desktop {
		font-size: 1rem;
	}	
}
.site-footer {
   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#dcfdfa+0,ffffff+100 */
   background: rgb(220,253,250); /* Old browsers */
   background: -moz-linear-gradient(top,  rgba(220,253,250,1) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
   background: -webkit-linear-gradient(top,  rgba(220,253,250,1) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
   background: linear-gradient(to bottom,  rgba(220,253,250,1) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dcfdfa', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
	&-grid {
	   @extend %block-padding;
		margin: 0 auto;
		@include tablet {
			display: flex;
		}	
		@include desktop {
			display: flex;
			max-width: $desktop;
		}			
	}
	&-col {
		text-align: center;
		margin: 0 auto;
      padding: 0 0 1em 0;
		@include tablet {
		   text-align: left;
			margin: 0 2% 0 0;	
         padding: 0;
		}
      &:first-child {
      color: $blue;
      font-size: .9rem;
         @include tablet { 
            width: 20%;
         }
      }
      &:nth-child(2) {
         @include tablet { 
            width: 10%;
         }
      }
      &:nth-child(3),
      &:nth-child(4) {
      width: 48%;
      color: $blue;
         @include tablet { 
            width: 15%;
         }
      }
      &:last-child {
      padding: 0;
      margin: 0;   
         @include tablet { 
            width: 32%;
         }
      }
	}	
	&-nav-list {
		text-align: left;
      list-style: none;
      margin: 0;
      padding: 0;
		&-link {
			@extend %footer-link;
			display: table;
			margin: 0 auto;
         font-weight: 700;
			@include tablet {
				margin: 0;
			}
		}
	}
	.social {
		margin: 1.5rem 0 1.5rem 0;
		@include tablet {
			margin-bottom: 0;
			margin: 1rem 0 1rem 0;			
		}
		@include desktop {
			margin: 1.5rem 0 1.5rem 0;			
		}				
		&-link {
			@include size(1.75rem, 1.75rem);
			@include tablet {
				@include size(1.35rem, 1.35rem);				
			}	
			@include desktop {
				@include size(1.75rem, 1.75rem);
			}				
		}
	}
}

.footer-extras-share {
   font-size: 1.0rem;
   margin-top: .5rem;
   position: relative;
   @include md {
  	font-size: 1.1rem;
	}
}

.footer-extras-share .social-link {
   margin-right: 0.2rem;
   display: inline-block;
   border-radius: 0;
   padding: 0;
   width: 20px;
}
.footer-extras-share .social-link svg {
   width: 100%;
   fill: $blue;
}
.footer-extras-share .social-link:hover svg {
   fill: $lightblue;
}

.paidfor {
	padding: .75rem;
	line-height: 1.3;
	border: 1px solid rgba($gray,.4);
	margin-bottom: 1.25rem;
	@include tablet {
		font-size: .7rem;
	}	
	@include desktop {
		font-size: 1rem;
	}		
}

.copyright {
   width: 100%;
   text-align: center;
   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#febdfd+10,6ca5e4+90 */
   background: rgb(254,189,253); /* Old browsers */
   background: -moz-linear-gradient(left,  rgba(254,189,253,1) 10%, rgba(108,165,228,1) 90%); /* FF3.6-15 */
   background: -webkit-linear-gradient(left,  rgba(254,189,253,1) 10%,rgba(108,165,228,1) 90%); /* Chrome10-25,Safari5.1-6 */
   background: linear-gradient(to right,  rgba(254,189,253,1) 10%,rgba(108,165,228,1) 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#febdfd', endColorstr='#6ca5e4',GradientType=1 ); /* IE6-9 */
	&-content {
		padding: 1rem 0;
      color: $blue;
		@include tablet {
			font-size: .8rem;
		}	
		@include desktop {
			font-size: 0.9rem;
		}			
	}
	&-nav {
		&-link {
			@extend %footer-link;
			display: inline-block;
		}
	}
}

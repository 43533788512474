.main-logo {
   width: 95px;
	margin: 0 auto;	
	display: inline-block;
	position: relative;
	z-index: 200;
	transition: $slow;
   top: 5px;
	padding: .5rem;
	&:focus {
		box-shadow: inset 0 0 0 2px $primary;
	}
   @include tablet {
      width: 180px;
	}
	@include tablet-wide {
      width: 220px;
	}
	.svg {
		width: 100%;
		fill: $primary;
		transition: $basic;
	}
	&:hover {
		.svg {
			fill: $secondary;
		}
	}
}
.home .main-logo {
   top: 5px;
   transition: $slow;
   @include tablet {
      top: 90px;
   }
   @include tablet-wide {
      top: 110px;
   }
}
.fixed .main-logo,
.interior .main-logo {
   width: 95px;
   top: 5px;
}
